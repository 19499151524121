<template>
  <el-dialog
    :title="'Change Conversion Status'"
    :visible.sync="setShow"
    @close="closeEvent"
    width="30%"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="Conversion"
            prop="status"
            :rules="validateField()"
          >
            <el-select
              v-model="form.status"
              multiple
              placeholder="Select a conversion status"
            >
              <el-option
                v-for="(status, index) in status"
                :key="index"
                :label="status"
                :value="status === 'N/A' ? status : status.toLowerCase()"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button
        type="primary"
        :loading="changing"
        :disabled="!form.status.length"
        @click="change"
        >Change status</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import payment from "@/requests/gardeners/payment-tracker";

export default {
  name: "PaymentConversionStatus",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        status: [],
      },
      status: ["Cross-sell", "Downsell", "Resurrection", "Upsell", "N/A"],

      changing: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.status = this.customer.conversion.map((status) =>
          status.toLowerCase(),
        );
      }
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
      this.$refs.form.resetFields();
    },
    change() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.changing = true;

        const payload = {
          tracker_id: this.customer.tracker_id,
          conversion_type: this.form.status,
        };

        payment
          .update(payload)
          .then((response) => {
            const { status, message, data } = response.data;
            if (status) {
              this.$message.success(message);
              this.$emit("update", data);
              this.closeEvent();
            }
            this.changing = false;
          })
          .catch((error) => {
            this.changing = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
  },
};
</script>

<style lang="scss"></style>
